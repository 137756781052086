import * as amplitude from '@amplitude/analytics-browser';
const initAmplitude = (apiKey, userId = null) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line
    // prettier-ignore
    const globalAmplitude = window.amplitude = window.amplitude || {};
    if (!globalAmplitude.initialize) {
      if (globalAmplitude.invoked) {
        reject('Amplitude snippet included twice.');
      } else {
        globalAmplitude.invoked = true;
        try {
          amplitude.init(apiKey, userId, {
            useBatch: true, //use batch mode with batch API endpoint, `https://api2.amplitude.com/batch`
            defaultTracking: false,
            minIdLength: 1 //userId length should be at least 1
          });

          globalAmplitude.amplitude = {
            ...globalAmplitude,
            initialize: true,
            ...amplitude
          };
          Object.assign(window.amplitude, globalAmplitude.amplitude);
          resolve(globalAmplitude);
        } catch (error) {
          reject('Unable to init Amplitude');
        }
      }
    } else {
      reject('Amplitude already initialized.');
    }
  });

export default initAmplitude;
