const log = require('debug')('analytics:stackshare');
import initSegment from './shared/utils/analytics/segment';
import initAmplitude from './shared/utils/analytics/amplitude';
import ActivityTracker from './shared/utils/analytics/activity-tracker';

// eslint-disable-next-line no-console
console.log('installing global tracking functions...');
let userStatus = false;
let impersonatedUser = false;

window.trackEvent = (name, data, depth = 0) => {
  if (window.analytics && !impersonatedUser) {
    try {
      JSON.stringify(data);
      window.analytics.track(name, data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Invalid payload', data);
    }
  } else {
    if (depth < 30) {
      // retry in 1 sec; allows analytics to bootstrap
      setTimeout(() => window.trackEvent(name, data, depth + 1), 1000);
    } else {
      // eslint-disable-next-line no-console
      console.warn('trackEvent backoff limit reached', name, data);
    }
  }

  //amplitude event
  if (window.amplitude && userStatus && !impersonatedUser) {
    try {
      JSON.stringify(data);
      window.amplitude.track(name, data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Invalid payload', data);
    }
  }
};
window.trackPage = (name, data) => {
  if (window.analytics && !impersonatedUser) {
    try {
      JSON.stringify(data);
      window.analytics.page(name, data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Invalid payload', data);
    }
  }

  //amplitude page event
  if (window.amplitude && userStatus && !impersonatedUser) {
    try {
      JSON.stringify(data);
      window.amplitude.track(`${name} is Viewed`, data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Invalid payload', data);
    }
  }
};

if (typeof window !== 'undefined') {
  fetch(`/user_properties.json?current_path=${window.location.pathname}`)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Error fetching user properties, response not OK');
    })
    .then(user => {
      const getUserId = user ? user.id : null;

      //change user status based on user id
      if (getUserId) {
        userStatus = true;

        //If user is impersonated, don't initialize analytics
        if (user.impersonated) {
          impersonatedUser = true;
          return false;
        }
      }

      log('initializing Segment...');
      initSegment(process.env.SEGMENT_KEY).then(_analytics => {
        log('Segment loaded', _analytics);

        if (window._PAGE) {
          log('setting page context', window._PAGE.name, window._PAGE.properties);
          _analytics.page(window._PAGE.name, window._PAGE.properties);
        } else {
          log('setting empty page context');
          _analytics.page();
        }

        log('installing ActivityTracker...');
        document.addEventListener('DOMContentLoaded', () => ActivityTracker(_analytics));

        if (getUserId) {
          log(`identifying analytics user ${user.id}`, user);
          _analytics.identify(String(user.id), user, () => log(`user ${user.id} identified`));
        } else {
          log('identifying anonymous analytics user', user);
          _analytics.identify(user, () => log(`anonymous user identified`));
        }
      });

      if (getUserId) {
        log('initializing amplitude...');
        initAmplitude(process.env.AMPLITUDE_API_KEY, user.id)
          .then(_amplitude => {
            log('Amplitude loaded!', _amplitude);

            if (window._PAGE) {
              log('setting page context', window._PAGE.name, window._PAGE.properties);
              _amplitude.track(`${window._PAGE.name} is Viewed`, window._PAGE.properties);
            } else {
              log('setting empty page context');
              _amplitude.track();
            }
          })
          .catch(err => log(err.message));
      }
    })
    .catch(err => log(err.message));
}
